<template>
  <div v-can="'RepMedReg1'">
    <section class="header">
      <div class="title">
        Regras de repasse
        <span id="help-icon" class="icon-box"><HelpCircle class="icon stroke" /></span>
        <b-tooltip target="help-icon" placement="bottom">
          Para não entrar em conflito as aplicações <br/>
          regras de repasse, é possível somente editar, <br/>
          ativar ou inativar as regras, conforme as <br/>
          definições necessárias para o repasse.
        </b-tooltip>

      </div>

      <b-button 
        v-can="'RepMedReg2'"
        :disabled="!hasConfiguration" 
        variant="primary" 
        @click="openRuleModal({})"
      >
        Adicionar regra
      </b-button>
    </section>

    <section>
      <Filters @changeFilters="getRules" />
    </section>

    <hr class="rules-divider"/>

    <section v-if="hasConfiguration">
      <b-row v-if="rules && rules.length">
        <b-col 
          cols="12"  
          v-for="rule in rules"
          :key="rule.id"
          class="mb-4"
        >
          <RuleCard
            :rule="rule"
            :openRuleModal="openRuleModal"
          />
        </b-col>
      </b-row>

      <b-row v-else>
        <b-col class="empty">
          Os filtros aplicados não geraram nenhum resultado.
        </b-col>
      </b-row>
    </section>

    <b-row v-else>
      <b-col class="empty">
        Configure o repasse médico na aba 'Configurações' para criar regras de repasse
      </b-col>
    </b-row>

    <RuleModal
      :rule="rule"
      @createRule="getRules"
      @updateRule="getRules"
      @closeModal="closeModal"
      :rules="rules"
    />
  </div>
</template>

<script>
import api from '../api'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'Rules',
  metaInfo: {
    title: 'Eyecare - Repasse médico - Regras de repasse'
  },
  components: {
    Filters: () => import('@/modules/onlending/components/rule/Filters.vue'),
    HelpCircle : () => import('@/assets/icons/lightblue-help-circle.svg'),
    RuleCard: () => import('@/modules/onlending/components/rule/RuleCard.vue'),
    RuleModal: () => import('@/modules/onlending/modals/rule/RuleModal.vue'),
  },
  async created() {
    await this.getRules()
  },
  props:{
    hasConfiguration: Boolean
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      filters: [],
      rules: null,
      rule: {}
    }
  },
  methods: {
    getRules(filters) {
      const isLoading = this.$loading.show()
      const data = {
        clinic_id: this.clinic.id,
        filters: filters ?
        {           
          professional_group: filters.professional_group,
          beneficiary_id: filters.beneficiary_id ? filters.beneficiary_id.id : null,
          item_group: filters.item_group,
          item_id: filters.item_id ?? null
        } : 
        null
      }
      api.getOnlendingRulesByClinicId(data)
      .then((res) => {
        this.rules = res.data
      })
      .catch ((error) => {
        if(filters) this.$toast.warning('Os filtros aplicados encontraram problemas com o resultado!')
        else this.$toast.error(error.message)
      })
      .finally (() => {
        isLoading.hide()
      })
    },
    openRuleModal(rule) {
      rule.number = rule.number ? rule.number : this.rules ? this.rules.length+1 : 1
      this.rule = rule
      this.$bvModal.show('rule-modal')
    },
    closeModal() {
      this.rule = {}
    }
  }
}
</script>

<style lang="scss" scoped>
#help-icon {
  height: 28px;
  margin-left: 5px;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px 0;

  .title {
    font-family: 'Nunito Sans';
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0;
    color: var(--type-active);
    display: flex;
    align-items: center;
  }
}

.rules-divider {
  margin: .5rem 0 2rem;
  border: 0.1rem dashed var(--neutral-300);
}

.flex-profiles {
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 1000px) {
    flex-direction: column;

    .profile-card {
      width: 100%;
    }
  }
}

.empty{
  text-align: center;
  padding: 100px 0px;
  color: var(--type-active);
}
</style>